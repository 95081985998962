<template>
  <ChangePasswordPage />
</template>

<script>
import ChangePasswordPage from "@/components/layout/user/ChangePasswordPage";
export default {
  name: "EditProfile",
  components: { ChangePasswordPage }
};
</script>

<style scoped></style>
